<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Initiate Mpesa Payment</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="initiateMpesaPayment"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p>{{ description }}</p>
                  <v-checkbox
                    v-if="contactNumber"
                    v-model="useContactNumber"
                    label="Use your default phone number"
                  ></v-checkbox>
                  <v-text-field
                    v-model="form.phone_number"
                    label="Phone Number"
                    placeholder="Enter phone number"
                    :rules="[v => !!v || 'Phone Number is required']"
                    :error="form.errors.has('phone_number')"
                    :error-messages="form.errors.get('phone_number')"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-if="showAmount"
                    v-model="form.amount"
                    label="Amount"
                    placeholder="Enter amount"
                    :rules="[v => !!v || 'Amount is required']"
                    :error="form.errors.has('amount')"
                    :error-messages="form.errors.get('amount')"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Pay
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <confirm-dialog
      :show-dialog="showConfirmDialog"
      :show-cancel-button="!showAmount"
      :title="'Complete payment'"
      :message="'Please check your phone for a prompt to enter your pin in order to complete the payment.'"
      :agree-text="confirmDialogAgreeText"
      :is-agree-button-loading="isCheckingPaymentStatus"
      :loading-message="loadingMessage"
      @cancel="showConfirmDialog = false"
      @agree="onConfirmDialogAgree"
    />
    <confirm-dialog
      :show-dialog="showPaymentReceivedDialog"
      title="Payment Received"
      message="Your payment has been received. You will be redirected shortly. If you're not redirected within 10 seconds, please click 'Redirect Now' button"
      agree-text="Redirect Now"
      :is-agree-button-loading="isRedirecting"
      @agree="redirectToRouter"
    />
  </v-row>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import { mapGetters } from 'vuex'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import routerLoginMixin from '@/mixins/routerLoginMixin'

export default {
  components: {
    ConfirmDialog,
  },
  mixins: [routerLoginMixin],
  props: {
    showDialog: {
      type: Boolean,
    },
    customer: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    showAmount: {
      type: Boolean,
      default: true,
    },
    contactNumber: {
      type: String,
      default: '',
    },
    amount: {
      type: Number,
      default: 0,
    },
    selectedPlan: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showConfirmDialog: false,
    form: new Form({
      phone_number: '',
      amount: '',
      internet_plan_id: '',
    }),
    useContactNumber: false,
    paymentStatusCheckTimer: null,
    isCheckingPaymentStatus: false,
    loadingMessage: '',
    showPaymentReceivedDialog: false,
    isRedirecting: false,
  }),
  computed: {
    ...mapGetters(['userInfo']),
    confirmDialogAgreeText() {
      return this.description ? "I've Paid" : 'OK'
    },
  },
  watch: {
    useContactNumber(newValue) {
      if (newValue) {
        this.form.phone_number = this.contactNumber
      } else {
        this.form.phone_number = ''
      }
    },
    amount(newAmount) {
      this.form.amount = newAmount
    },
    contactNumber(newContactNumber) {
      this.form.phone_number = newContactNumber
      this.useContactNumber = true
    },
    showDialog(newValue) {
      if (newValue) {
        this.initializeForm()
      }
    },
    selectedPlan(newPlan) {
      if (newPlan && newPlan.id) {
        this.form.internet_plan_id = newPlan.id
      }
    },
  },
  mounted() {
    this.initializeForm()
  },
  methods: {
    initializeForm() {
      if (this.contactNumber) {
        this.useContactNumber = true
        this.form.phone_number = this.contactNumber
      }
      if (this.amount) {
        this.form.amount = this.amount
      }
      if (this.selectedPlan && this.selectedPlan.id) {
        this.form.internet_plan_id = this.selectedPlan.id
      }
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.phone_number = ''
      this.form.amount = ''
    },
    checkPaymentStatus(isManualCheck = false) {
      this.isCheckingPaymentStatus = true
      this.loadingMessage = isManualCheck ? 'Confirming payment...' : 'Waiting for your payment...'
      axios.post('customer/check-payment-status', {
        isManualCheck,
      })
        .then(response => {
          this.handlePaymentStatusResponse(response.data)
        })
        .catch(error => {
        // Handle errors (e.g., display a toast notification)
          console.error('Error checking payment status:', error)
        }).finally(() => {
          this.isCheckingPaymentStatus = false
          this.loadingMessage = ''
        })
    },
    handlePaymentStatusResponse(data) {
      if (data.status === 'active') {
        this.showConfirmDialog = false
        this.showPaymentReceivedDialog = true
        this.loginToRouter(data.data.username, data.data.router_auth_password, this.userInfo.routerLoginLink)
      } else {
        // Show appropriate message
        this.$toast.info(data.message)
      }
    },
    initiateMpesaPayment() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('initiate-payment/mpesa')
          .then(response => {
            this.showConfirmDialog = true
            this.$emit('close')
            this.$emit('mpesa-payment-initiated', response.data)
            this.closeDialog()
            if (this.form.internet_plan_id !== null) {
              // Check payment status for hotspot users only
              this.startPaymentStatusCheck()
            }
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
    onConfirmDialogAgree() {
      if (this.confirmDialogAgreeText === 'I\'ve Paid') {
        this.onAgreePaymentConfirmation()

        return
      }

      this.showConfirmDialog = false
    },
    onConfirmDialogCancel() {
      this.showConfirmDialog = false
      this.clearPaymentStatusCheckTimer()
    },
    onAgreePaymentConfirmation() {
      this.checkPaymentStatus(true) // User-initiated check
      this.clearPaymentStatusCheckTimer() // Clear the timer to prevent automatic check
    },

    clearPaymentStatusCheckTimer() {
      if (this.paymentStatusCheckTimer) {
        clearTimeout(this.paymentStatusCheckTimer)
        this.paymentStatusCheckTimer = null
      }
    },

    startPaymentStatusCheck() {
      if (!this.paymentStatusCheckTimer) { // Set timer only if it's not already set
        this.paymentStatusCheckTimer = setTimeout(() => {
          this.checkPaymentStatus() // Automatic check after 4 seconds
        }, 4000)
      }
    },
    redirectToRouter() {
      this.isRedirecting = true
      this.loginToRouter(this.customer.username, this.customer.router_auth_password, this.userInfo.routerLoginLink)
    },
  },
}
</script>
